const RequiredFieldText = {
    "dk": "Feltet er påkrævet",
    "no": "Feltet må utfylles",
    "se": "Detta fält måste fyllas i"
}

const ValidAddress = {
    "dk": "Desværre genkender systemet ikke det område, du har indtastet. Prøv at tilføje by, postnummer eller gadenavn.",
    "no": "Systemet gjenkjenner dessverre ikke det området du har tastet inn. Skriv vennligst sted, postnummer eller gatenavn.",
    "se": "Tyvärr hittar inte systemet igen platsen du har angett. Prova att lägga till en stad, postnummer eller gatunamn."
}

const ValidFullName = {
    "dk": "Skriv dit fulde navn",
    "no": "Inntast fullt navn",
    "se": "Fyll i ditt fullständiga namn"
}
const ValidEmailAddress = {
    "dk": "Skriv dit e-mailadresse",
    "no": "Inntast e-postadresse",
    "se": "Fyll i din email adress"
}
const ValidPhoneNumber = {
    "dk": "Indtast venligst et gyldigt telefonnummer. Eksempelvis:",
    "no": "Vennligst legg inn et gyldig telefonnummer. For eksempel:",
    "se": "Vänligen ange ett giltigt telefonnummer. Till exempel:"
}
const ValidHouseSize = {
    "dk": "Skriv dit boligareal",
    "no": "Inntast boligareal",
    "se": "Fyll i din boyta"
}
const ValidLandSize = {
    "dk": "Skriv dit grundareal",
    "no": "Inntast tomteareal",
    "se": "Fyll i din tomt storlek"
}
const ValidDistance = {
    "dk": "Skriv afstanden i tal",
    "no": "Inntast avstand i tall",
    "se": "Fyll i avstånd i siffror"
}
const ValidZipCode = {
    "dk": "Skriv dit postnummer",
    "no": "Inntast postnummer",
    "se": "Fyll i ditt post nummer"
}
const ValidAcNumber = {
    "dk": "Skriv dit kontonr.",
    "no": "Inntast kontonummer",
    "se": "Fyll i ditt bankkontonummer"
}
const ValidRegnrNumber = {
    "dk": "Indtast dit reg. nummer",
    "no": "Vennligst skriv inn gyldig konto nummer",
    "se": "Ange ditt registreringsnummer"
}
const ValidCity = {
    "dk": "Skriv by",
    "no": "Inntast sted",
    "se": "Fyll by "
}
const AcceptAgreement = {
    "dk": "Acceptér aftalen",
    "no": "Godkjenn avtalen",
    "se": "Godkänn avtalet"
}
const AcceptTermsAndConditions = {
    "dk": "Før du fortsætter har vi brug for dit samtykke",
    "no": "Før du fortsetter, trenger vi ditt samtykke.",
    "se": "Innan du kan fortsätta behöver vi ditt samtycke"
}

const InvalidSession = {
    "dk": "Ugyldig session!",
    "no": "Ugyldig sesjon!",
    "se": "Ogiltig session!"
}
const SessionExpire = {
    "dk": "Sessionen er udløbet!",
    "no": "Sesjonen har utløpt!",
    "se": "Sessionen löpt ut!"
}
const InvalidAttempt = {
    "dk": "Ugyldigt forsøg!",
    "no": "Ugyldig forsøk!",
    "se": "Ogiltigt försök!"
}
const SomethingWentWrong = {
    "dk": "Noget gik galt!",
    "no": "Noe gikk galt!",
    "se": "Något gick fel!"
}

const ValidHouseSizeRange = {
    "dk": "Arealet på huset skal være mellem 1 og 32765 m2",
    "no": "Boligareal må være mellom 1 og 32765 kvm",
    "se": "Husets storlek bör vara mellan 1 och 32765 kvm"
}

const ValidPlotSizeRange = {
    "dk": "Arealet på grunden skal være mellem 1 og 32765 m2",
    "no": "Tomteareal må være mellom 1 og 32765 kvm",
    "se": "tomtstorleken ska vara mellan 1 och 32 765 kvm"
}

export { ValidAddress, AcceptAgreement, AcceptTermsAndConditions, RequiredFieldText, InvalidAttempt, InvalidSession, SessionExpire, SomethingWentWrong, ValidAcNumber, ValidCity, ValidDistance, ValidEmailAddress, ValidFullName, ValidHouseSize, ValidLandSize, ValidPhoneNumber, ValidRegnrNumber, ValidZipCode, ValidHouseSizeRange, ValidPlotSizeRange };