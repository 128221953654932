// import getConfig from 'next/config'
// const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export const BackendBaseURL = {
    //"BACKEND_BASE_URL": 'https://holead-bff-dancenter-dk.oyorooms.ms'
    "BACKEND_BASE_URL": process.env.NEXT_PUBLIC_BACKEND_BASE_URL
}

export const HostNameBaseURL = {
    "staging": {
        "dk": 'https://holead-dancenter-dk-stg.oyorooms.ms/',
        "se": 'https://holead-dancenter-se-stg.oyorooms.ms/',
        "no": 'https://holead-dancenter-no-stg.oyorooms.ms/',
    },
    "prod": {
        "dk": 'https://holead-dancenter-dk-prod.oyorooms.com/',
        "se": 'https://holead-dancenter-se-prod.oyorooms.com/',
        "no": 'https://holead-dancenter-no-prod.oyorooms.com/',
    }
}

export const HostNamePart = {
    "dk": 'dancenter-dk',
    "se": 'dancenter-se',
    "no": 'dancenter-no'
}

export const BaseURL = {
    "dk": 'https://www.dancenter.dk/udlejning-sommerhus/',
    "se": 'https://www.stugsommar.se/hyra-ut-stuga/',
    "no": 'https://www.dancenter.no/utleie-av-hytte/',
    "ads_da": 'https://www.admiralstrand.dk/udlejning-sommerhus/'
}

export const QrFormBaseURL = {
    "dk": 'https://www.dancenter.dk/udlejning-sommerhus/qrcode/',
    "se": 'https://www.stugsommar.se/hyra-ut-stuga/qrcode/',
    "no": 'https://www.dancenter.no/utleie-av-hytte/qrcode/',
    "ads_da": 'https://www.admiralstrand.dk/udlejning-sommerhus/qrcode/'
}