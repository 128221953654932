import styles from "../../styles/HeaderFooter.module.scss"
import FooterRefs from "./FooterRefs";
import Router from 'next/router';
import { LocaleVariables } from "../../constants/appConstants/Locales";

const PrincipalFooter = ({ dataForHomePage, locale }: { dataForHomePage: any, locale?: any }) => {
    const basePath = dataForHomePage;

    const handleLocaleOnChange = (e: { target: { value: string } }) => {
        Router.push(e.target.value);
    }

    return (
        <div className={styles.footerLinks}>
            <div className={styles.container}>
                <div className={styles.footerRow}>
                    <FooterRefs headerText={basePath?.footerRef[0]?.headerText} navLinks={basePath?.footerRef[0]?.navLinks} />
                    <FooterRefs headerText={basePath?.footerRef[1]?.headerText} navLinks={basePath?.footerRef[1]?.navLinks} />
                    {locale === LocaleVariables.se && <FooterRefs headerText={basePath?.footerRef[2]?.headerText} navLinks={basePath?.footerRef[2]?.navLinks} />}
                    {locale !== LocaleVariables.ads_da && <div className={styles.footerColumnFrame}>
                        <div className={styles.buttonBox}>
                            <div className={styles.languageSelect}>{basePath?.languageChoices?.heading}</div>
                            <select tabIndex={-1} onChange={(e) => handleLocaleOnChange(e)} className={styles.buttonControl}>
                                {locale === LocaleVariables.se && <>
                                    <option value={basePath?.languageChoices?.navLinks[1]?.reference}>{basePath?.languageChoices?.navLinks[1]?.title}</option>
                                    <option value={basePath?.languageChoices?.navLinks[0]?.reference}>{basePath?.languageChoices?.navLinks[0]?.title}</option>
                                    <option value={basePath?.languageChoices?.navLinks[2]?.reference}>{basePath?.languageChoices?.navLinks[2]?.title}</option>
                                </>}
                                {locale === LocaleVariables.no && <>
                                    <option value={basePath?.languageChoices?.navLinks[2]?.reference}>{basePath?.languageChoices?.navLinks[2]?.title}</option>
                                    <option value={basePath?.languageChoices?.navLinks[0]?.reference}>{basePath?.languageChoices?.navLinks[0]?.title}</option>
                                    <option value={basePath?.languageChoices?.navLinks[1]?.reference}>{basePath?.languageChoices?.navLinks[1]?.title}</option>
                                </>}
                                {locale === LocaleVariables.dk && <>
                                    <option value={basePath?.languageChoices?.navLinks[0]?.reference}>{basePath?.languageChoices?.navLinks[0]?.title}</option>
                                    <option value={basePath?.languageChoices?.navLinks[1]?.reference}>{basePath?.languageChoices?.navLinks[1]?.title}</option>
                                    <option value={basePath?.languageChoices?.navLinks[2]?.reference}>{basePath?.languageChoices?.navLinks[2]?.title}</option>
                                </>}
                            </select>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default PrincipalFooter;