import { useState, useEffect } from "react";
import { LocaleVariables } from "../../constants/appConstants/Locales";
import { isHeaderFooterRequired } from "../../utils/validationUtil";
import CopyrightFooter from "./CopyrightFooter";
import PrincipalFooter from "./PrincipalFooter";

const Footer = ({ dataForHomePage, locale }: { dataForHomePage: any, locale?: any }) => {
    const [homePage, setHomePage] = useState<boolean>(false);
    useEffect(() => {
        if (typeof window !== 'undefined')
            setHomePage(isHeaderFooterRequired(window.location.pathname, locale));
    }, [])
    return (
        <footer data-testid='footer-component'>
            {homePage && <>
                {locale != LocaleVariables.ads_da && <PrincipalFooter dataForHomePage={dataForHomePage} locale={locale} />}
                <CopyrightFooter dataForHomePage={dataForHomePage?.copyrightFooter} locale={locale} />
            </>}
            {!homePage && <CopyrightFooter dataForHomePage={dataForHomePage?.copyrightFooter} />}
        </footer>
    );
}

export default Footer;