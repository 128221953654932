import styles from "../../styles/HeaderFooter.module.scss"

const ProgressBarComponent = ({ formNumber, id }: { formNumber: any, id: number }) => {
    return (

        <div data-testid={`progress-bar-${id}`} className={formNumber >= id ? styles["progress-bar-section-selected"] : styles["progress-bar-section"]}>
            <div data-testid={`progress-bar-dot-${id}`} className={formNumber >= id ? styles["progress-bar-section-dot-selected"] : styles["progress-bar-section-dot"]}></div>
        </div>
    );
}

export default ProgressBarComponent;