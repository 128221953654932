import { InvalidAttempt, InvalidSession, SessionExpire, SomethingWentWrong } from "../constants/appConstants/HoLeadErrors";
import { toast } from 'react-toastify';
import { LocaleVariables } from "../constants/appConstants/Locales";
import Router from 'next/router';
import { getPushURL } from "./getURLs";


const unexpectedError = (locale: string) => {
    if (locale === LocaleVariables.no) {
        toast.error(SomethingWentWrong.no, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else if (locale === LocaleVariables.se) {
        toast.error(SomethingWentWrong.se, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else {
        toast.error(SomethingWentWrong.dk, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
}

const sessionExpireError = (locale: string) => {
    if (locale === LocaleVariables.no) {
        toast.error(SessionExpire.no, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else if (locale === LocaleVariables.se) {
        toast.error(SessionExpire.se, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else {
        toast.error(SessionExpire.dk, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
}
const invalidAttemptError = (locale: string) => {
    if (locale === LocaleVariables.no) {
        toast.error(InvalidAttempt.no, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else if (locale === LocaleVariables.se) {
        toast.error(InvalidAttempt.se, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else {
        toast.error(InvalidAttempt.dk, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
}

const invalidSessionError = (locale: string) => {
    if (locale === LocaleVariables.no) {
        toast.error(InvalidSession.no, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else if (locale === LocaleVariables.se) {
        toast.error(InvalidSession.se, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
    else {
        toast.error(InvalidSession.dk, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }
}

const apiErrorHandling = (response: any, locale: any) => {
    if (response.status === 403) {
        invalidSessionError(locale);
        if (typeof window !== 'undefined') {
            window.sessionStorage.clear();
            Router.push('/', getPushURL(window.location.hostname, '/'));
        }
    }
    else if (response.status === 401) {
        sessionExpireError(locale);
        if (typeof window !== 'undefined') {
            window.sessionStorage.clear();
            Router.push('/', getPushURL(window.location.hostname, '/'));
        }
    }
    else if (response.status === 200) {
        return response;
    }
    else {
        if (response?.statusText != undefined && response?.statusText != "") {
            toast.error(response.statusText, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false
            });
        }
        else {
            unexpectedError(locale);
        }
        if (typeof window !== 'undefined') {
            window.sessionStorage.clear();
            Router.push('/', getPushURL(window.location.hostname, '/'));
        }
    }
    return response;
}

const handleContentAPIResponse = async (response: any) => {
    if (response.status != 200) {
        toast.error(SomethingWentWrong.dk, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
        if (typeof window !== 'undefined') {
            window.sessionStorage.clear();
            Router.push('/', getPushURL(window.location.hostname, '/'));
        }
    }
    else return response;
}

const processResponse = async (response: any) => {
    let processedResponse = await response.json();

    if (response['ok'] === false) {
        processedResponse['success'] = false;
    } else {
        processedResponse['success'] = true;
    }
    return processedResponse;
}

export { unexpectedError, sessionExpireError, invalidSessionError, invalidAttemptError, apiErrorHandling, handleContentAPIResponse, processResponse };