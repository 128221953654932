import { LocaleKeyWord, Locales, LocaleVariables } from "../constants/appConstants/Locales";
import { BaseURL, QrFormBaseURL } from "../constants/appConstants/Urls";


const localeString = (locale: any) => {
    switch (locale) {
        case Locales.se:
            return LocaleVariables.se;
        case Locales.no:
            return LocaleVariables.no;
        case Locales.ads_da:
            return LocaleVariables.ads_da;
        default:
            return LocaleVariables.dk;
    }
}

const localWiseKeyWord = (locale: any) => {
    switch (locale) {
        case LocaleVariables.se:
            return LocaleKeyWord.se;
        case LocaleVariables.no:
            return LocaleKeyWord.no;
        case LocaleVariables.ads_da:
            return LocaleKeyWord.ads_da;
        default:
            return LocaleKeyWord.dk;
    }
}

const localWiseBaseURL = (locale: any) => {
    switch (locale) {
        case LocaleVariables.se:
            return BaseURL.se;
        case LocaleVariables.no:
            return BaseURL.no;
        case LocaleVariables.ads_da:
            return BaseURL.ads_da;
        default:
            return BaseURL.dk;
    }
}
const localWiseQrFormBaseURL = (locale: any) => {
    switch (locale) {
        case LocaleVariables.se:
            return QrFormBaseURL.se;
        case LocaleVariables.no:
            return QrFormBaseURL.no;
        case LocaleVariables.ads_da:
            return QrFormBaseURL.ads_da;
        default:
            return QrFormBaseURL.dk;
    }
}
// ${currentQuestion === questionSix ? styles["open"] : (submittedQuestion >= questionSix ? styles['completed'] : "")}
const queStyle = (curQues: number, subQues: number, quesNum: number, styles: any) => {
    if (curQues === quesNum) {
        return styles["open"];
    }
    if (subQues >= quesNum) {
        return styles["completed"]
    }
    return "";
}

const handleForm1QuestionAnswer = (ansNum: number, formDataObject: any, quesNum: number) => {
    quesNum = quesNum - 1;
    switch (ansNum) {
        case 1:
            return formDataObject?.questions[quesNum]?.options[0];
        case 2:
            return formDataObject?.questions[quesNum]?.options[1];
        case 3:
            return formDataObject?.questions[quesNum]?.options[2];
        default:
            return "";

    }
}

export { localeString, queStyle, handleForm1QuestionAnswer, localWiseKeyWord, localWiseBaseURL, localWiseQrFormBaseURL };