import { useState, useEffect } from "react";
import styles from "../../styles/HeaderFooter.module.scss"
import { isHeaderFooterRequired } from "../../utils/validationUtil";

const CopyrightFooter = ({ dataForHomePage, locale }: { dataForHomePage: any, locale?: any }) => {
    const basePath = dataForHomePage;
    const [homePage, setHomePage] = useState<boolean>(false);
    useEffect(() => {
        if (typeof window !== 'undefined')
            setHomePage(isHeaderFooterRequired(window.location.pathname, locale));
    }, [])
    return (
        <div className={styles.footerCopyright}>
            <div className={styles.footerDivText}>
                {homePage &&
                    <>
                        <div>{basePath?.heading}</div>
                        <p>{basePath?.subHeading} </p>
                    </>
                }
            </div>
        </div>
    );
}

export default CopyrightFooter;