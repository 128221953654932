import Link from "next/link";
import styles from "../styles/HeaderFooter.module.scss"
import * as gtag from '../lib/gtag'
import Image from "next/image";
import Router from 'next/router';
import { returnImageURL } from "../utils/getURLs";
import { useState, useEffect } from "react";
import ProgressBarComponent from "./detailsForms/ProgressBarComponent";
import { LocaleVariables } from "../constants/appConstants/Locales";
import { isHeaderFooterRequired } from "../utils/validationUtil";

const handleClickOnNavBarBtn = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}

const Navbar = ({ activeSection, dataForHomePage, formNumber, locale }: { activeSection: any, dataForHomePage: any, formNumber?: any, locale?: any }) => {
    const basePath = dataForHomePage;
    const basePathForImage = dataForHomePage?.navigationLogo?.data[0]?.attributes;
    const [screenWidth, setScreenWidth] = useState<number>(0);
    const [homePage, setHomePage] = useState<boolean>(false);

    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
        if (typeof window !== 'undefined')
            setHomePage(isHeaderFooterRequired(window.location.pathname, locale));
    }, [])

    return (
        <>
            <nav data-testid='navbar-component' className={locale == LocaleVariables.se ? styles["nav-stugsommar"] : (locale == LocaleVariables.dk || locale == LocaleVariables.no) ? styles["nav-dk"] : locale === LocaleVariables.ads_da ? (!formNumber ? `${styles["nav-ads"]} ${styles["nav-ads-border-bottom"]}` : styles["nav-ads"]) : styles["nav"]}>
                {homePage &&
                    <>
                        <button onClick={() => {
                            if (typeof window !== 'undefined') {
                                window.sessionStorage.clear();
                                window.location = basePath?.logoLink;
                            }
                            // Router.push(basePath?.logoLink);
                        }} className={styles["link-logo"]}>
                            {(locale == LocaleVariables.dk || locale === LocaleVariables.no) && <Image priority src={returnImageURL(basePathForImage, screenWidth)} width={128} height={31.8} alt="Dancenter logo" />}
                            {locale === LocaleVariables.se && <Image priority src={returnImageURL(basePathForImage, screenWidth)} width={160} height={33} alt="Stugsommar logo" />}
                            {locale === LocaleVariables.ads_da && <Image priority src={returnImageURL(basePathForImage, screenWidth)} width={160} height={60} alt="Admiral Strand logo" />}
                        </button>
                        <ul className={styles["navBtnContainer"]} >
                            <li key={"whyus"}>
                                {/* set style in navbar when whyus is in view and startEarning and leadregister are not in view. */}
                                <Link href="#section-whyus"><a data-testid='navbar-btn-1' onClick={() => handleClickOnNavBarBtn('holead:landing page', 'top bar section', 'why us')} className={activeSection.whyUsInView && !activeSection.startEarningInView && !activeSection.leadRegisterInView ? (locale === LocaleVariables.ads_da ? styles["activeNavBtn-ads"] : styles["activeNavBtn"]) : (locale === LocaleVariables.ads_da ? styles["navBtn-ads"] : styles["navBtn"])} >{basePath?.buttonTitles[0]}</a></Link>
                            </li>
                            <li key={"start-earning"}>
                                {/* set style in navbar when startearning is in view and homeownerStatement is not in view */}
                                <Link href="#section-start-earning"><a data-testid='navbar-btn-2' onClick={() => handleClickOnNavBarBtn('holead:landing page', 'top bar section', 'start earning')} className={activeSection.startEarningInView && !activeSection.homeOwnerStatementInView ? (locale === LocaleVariables.ads_da ? styles["activeNavBtn-ads"] : styles["activeNavBtn"]) : (locale === LocaleVariables.ads_da ? styles["navBtn-ads"] : styles["navBtn"])}>{basePath?.buttonTitles[1]}</a></Link>
                            </li>
                            {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) &&
                                <li key={"statement-from-homeowner"}>
                                    {/* set style in navbar when homeownerStatement is in view and qna is not in view */}
                                    <Link href="#section-statement-from-homeowner"><a data-testid='navbar-btn-3' onClick={() => handleClickOnNavBarBtn('holead:landing page', 'top bar section', 'statement from homeowner')} className={activeSection.homeOwnerStatementInView && !activeSection.qnaInView ? (locale === LocaleVariables.ads_da ? styles["activeNavBtn-ads"] : styles["activeNavBtn"]) : (locale === LocaleVariables.ads_da ? styles["navBtn-ads"] : styles["navBtn"])}>{basePath?.buttonTitles[2]}</a></Link>
                                </li>
                            }
                            <li key={"qna"}>
                                {/* set style in navber when qna is in view */}
                                <Link href="#section-qna"><a data-testid='navbar-btn-4' onClick={() => handleClickOnNavBarBtn('holead:landing page', 'top bar section', 'QnA')} className={activeSection.qnaInView ? (locale === LocaleVariables.ads_da ? styles["activeNavBtn-ads"] : styles["activeNavBtn"]) : (locale === LocaleVariables.ads_da ? styles["navBtn-ads"] : styles["navBtn"])}>{basePath?.buttonTitles[3]}</a></Link>
                            </li>
                            {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) &&
                                <li key={"contactus"}>
                                    {/* set style in navbar when contactus is in view */}
                                    <Link href="#section-contactus"><a data-testid='navbar-btn-5' onClick={() => handleClickOnNavBarBtn('holead:landing page', 'top bar section', 'contact us')} className={activeSection.contactInView ? (locale === LocaleVariables.ads_da ? styles["activeNavBtn-ads"] : styles["activeNavBtn"]) : (locale === LocaleVariables.ads_da ? styles["navBtn-ads"] : styles["navBtn"])}>{basePath?.buttonTitles[4]}</a></Link>
                                </li>
                            }
                        </ul>
                        {!activeSection.leadRegisterInView &&
                            <Link href="#section-lead-register">
                                <a onClick={() => handleClickOnNavBarBtn('holead:rental_calculation', 'calculate rental button', 'top bar')} className={styles["btn"]}>
                                    {basePath?.calculateNowBtn}
                                </a>
                            </Link>
                        }
                    </>
                }

                {
                    !homePage &&
                    <>
                        <button onClick={() => {
                            if (typeof window !== 'undefined') {
                                window.sessionStorage.clear();
                                window.location = basePath?.logoLink;
                            }
                            // Router.push(basePath?.logoLink);
                        }} className={styles["link-logo"]}>
                            {(locale == LocaleVariables.dk || locale === LocaleVariables.no) && <Image priority src={returnImageURL(basePathForImage, screenWidth)} width={128} height={31.8} alt="Dancenter logo" />}
                            {locale === LocaleVariables.se && <Image priority src={returnImageURL(basePathForImage, screenWidth)} width={160} height={33} alt="Stugsommar logo" />}
                            {locale === LocaleVariables.ads_da && <Image priority src={returnImageURL(basePathForImage, screenWidth)} width={160} height={60} alt="Admiral Strand logo" />}
                        </button>
                    </>
                }
            </nav >
            {
                formNumber &&
                <>
                    <div className={styles["progress-bar"]}>
                        <ProgressBarComponent formNumber={formNumber} id={1} />
                        <ProgressBarComponent formNumber={formNumber} id={2} />
                        <ProgressBarComponent formNumber={formNumber} id={3} />
                        <ProgressBarComponent formNumber={formNumber} id={4} />
                        {/* <ProgressBarComponent formNumber={formNumber} id={5} /> */}
                        <ProgressBarComponent formNumber={formNumber} id={6} />
                        <ProgressBarComponent formNumber={formNumber} id={7} />
                        {/* <ProgressBarComponent formNumber={formNumber} id={8} /> */}
                    </div>
                </>
            }
        </>
    )
}

export default Navbar;
