import styles from "../../styles/HeaderFooter.module.scss";
import { useState } from "react";


interface FooterRefProps {
    headerText: string;
    navLinks: {
        title: string;
        reference: string;
    }[];
}

const FooterRefs = ({ headerText, navLinks }: FooterRefProps) => {
    const [isActive, setActive] = useState<boolean>(false);
    const handleClick = () => {
        setActive(!isActive);
    };

    return (
        <div className={styles.footerColumnFrame}>
            <div className={styles.referencesFrame}>
                <div className={styles.footerColumnHeading} onClick={handleClick}>{headerText}
                    <span className={isActive ? styles.iconChevron : styles.iconChevronCollapsed}></span>
                </div>
                <div className={isActive ? styles.footerMainLinks : styles.footerMainLinksCollapsed}>
                    <ul className={styles.footerUlStyle}>
                        {
                            navLinks.map((item) => (
                                <li className={styles.footerListItems} key={item.title}>
                                    <a href={item.reference} target="_blank" rel="noopener noreferrer" className={styles.footerA}>
                                        {item.title}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default FooterRefs;